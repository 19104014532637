<template>
    <div class="auth-box">
        <img class="auth-bg w-full" :src="require('@/assets/images/login-bg.svg')" alt="">

        <img :src="require('@/assets/images/discord-login.svg')" alt="" class="auth-logo">
        <transition :name="transitionName" mode="out-in">
            <router-view/>
        </transition>

    </div>
</template>

<script>
/* 组件 */
export default {
    name: 'layout',
    data() {
        return {
            transitionName: 'page'
        }
    },
    components: {},
    mixins: [],
    filters: {},
    props: {},
    computed: {},
    watch: {
        '$route' (to, from) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'page-back' : 'page'
        }
    },
    methods: {},
    created() {

    },
    mounted() {

    }
}
</script>

<style scoped>
@import "layout.scss";

.page-enter-active, .page-back-enter-active {
    animation: bounce-in 0.5s;
}
.page-leave-active, .page-back-leave-active {
    animation: fade-out 0.1s;
}
@keyframes bounce-in {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    20% {
        transform: translateY(-20px);
        opacity: 1;
    }
    70% {
        transform: translateY(5px);
    }
    /*80% {
        transform: translateY(-5px);
    }*/
    100% {
        transform: translateY(0);
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.3;
    }
    100% {
        opacity: 0;
    }
}
</style>