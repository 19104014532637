import Vue from 'vue';

Vue.directive('dloading', {
    bind(el: HTMLElement, binding: any) {
        const div = document.createElement('div');
        div.className = 'loading-dots';
        for (let i = 0; i < 3; i++) {
            const dot = document.createElement('div');
            div.appendChild(dot);
        }
        (el as any).loadingElement = div;
        if (binding.value) {
            el.appendChild(div);
        }
    },
    update(el: HTMLElement, binding: any) {
        if (binding.value) {
            if (!el.contains((el as any).loadingElement)) {
                el.appendChild((el as any).loadingElement);
            }
        } else {
            if (el.contains((el as any).loadingElement)) {
                el.removeChild((el as any).loadingElement);
            }
        }
    },
});