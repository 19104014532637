<template>
    <div class="divider-box">
        <div class="divider" :style="{margin: margin, 'border-bottom': `2px solid ${color}`}"></div>
    </div>
</template>

<script>
/* 组件 */
export default {
    name: 'Divider',
    data() {
        return {}
    },
    components: {},
    mixins: [],
    filters: {
    },
    props: {
        margin: {
            type: String,
            default: '8px 0'
        },
        color: {
            type: String,
            default: '#35363c'
        }
    },
    computed: {},
    watch: {},
    methods: {},
    created() {

    },
    mounted() {

    }
}
</script>

<style scoped>
.divider-box {
    width: 100%;
    display: flex;

    .divider {
        flex: 1;
        height: 2px;
        border-radius: 1px;
        box-sizing: border-box;
    }
}



</style>