import {get, post, upload} from '@/utils/request.js'

/**
 * 获取seed
 * @param jobId
 * @returns {Promise<unknown>}
 */
export const seed = (jobId) => get('/discord/seed', {jobId})

/**
 * 获取用户创建的所有频道
 */
export const channels = (serverId) => get('/discord/channels', {serverId})

/**
 * 获取频道的所有消息
 */
export const messages = (channelId, index, limit) => get('/discord/messages', {channelId, index, limit})

/**
 * 创建频道
 */
export const createChannel = (params) => post('/discord/create-channel', params)

/**
 * 删除频道
 */
export const deleteChannel = (channelId) => get('/discord/delete-channel', {channelId})

/**
 * 获取用户设置
 */
export const userSetting = (serverId, chatId) => get('/discord/user-setting', {serverId, chatId})

/**
 * 重置用户设置
 */
export const resetSetting = (serverId, chatId) => get('/discord/reset-setting', {serverId, chatId})

/**
 * 发送聊天消息
 */
export const sendMessage = (chat) => post('/discord/send-message', chat)

/**
 * 保存新的设置
 */
export const saveSetting = (chat) => post('/discord/save-setting', chat)

/**
 * 提交绘画任务
 */
export const submitTask = (params) => post('/discord/submit-task', params)

/**
 * 提交融图绘画任务
 */
export const blend = (params) => post('/discord/blend', params)

/**
 * 订阅
 */
export const subscribe = (params) => post('/discord/subscribe', params)

/**
 * 订阅
 */
export const enlarge = (params) => post('/discord/enlarge', params)

/**
 * 获取用户设置
 */
export const getSetting = (serverId) => get('/discord/get-setting', {serverId})

/**
 * 查询任务状态
 */
export const jobStatus = (jobId) => get('/discord/job-status/' + jobId, {})

/**
 * 批量查询任务状态
 */
export const status = (jobIds) => post('/discord/status', {chatIdList: jobIds})

/**
 * 批量查询任务状态
 */
export const info = (chatId) => get('/discord/info', {chatId})

/**
 * 上传文件
 */
export const uploadFile = (params) => upload('/discord/upload', params)

/**
 * 上传文件
 */
export const uploadByUrl = (params) => upload('/discord/uploadUrl', params)

/**
 * 上传文件
 */
export const uploadFileWithoutChat = (params) => upload('/discord/uploadFile', params)

/**
 * 反推描述词
 */
export const describe = (params) => post('/discord/describe', params)

/**
 * 换脸
 */
export const swapFace = (params) => post('/discord/swapFace', params)

/**
 * 更新消息
 */
export const updateMessage = (params) => post('/discord/update-message', params)

/**
 * 保存消息
 */
export const saveMessage = (params) => post('/discord/saveMessage', params)

/**
 * 删除消息
 */
export const removeMessage = (params) => post('/discord/removeMessage', params)

/**
 * 同步任务状态
 */
export const syncTask = (jobId) => get('/discord/syncTask', {jobId})

/**
 * 获取公告
 */
export const getNotice = (params) => get('/discord/notice-list', params)

/**
 * 更新图片链接
 */
export const saveTask = (id, url) => get('/discord/saveTask', {id, url})