import router from './router'
import store from './store'
import {Message} from 'element-ui'
import { storage } from '@/utils/Storage';
const dev = process.env.NODE_ENV === 'development'

router.beforeEach((to, from, next) => {

    document.title = `Discord · ${to.meta.title}`;

    let token = storage.get('token');
    if (to.path === '/login' || to.path === '/register') {
        next();
    } else {
        if (token) {
            next();
        } else {
            next('/login');
        }
    }
})