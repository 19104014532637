import {get, post} from '@/utils/request.js'

/**
 * 登录
 * @param params
 * @returns {Promise<unknown>}
 */
export const login = (params) => post('/auth/login', params)

/**
 * 退出
 * @param params
 * @returns {Promise<unknown>}
 */
export const logout = () => post('/auth/logout', {})

/**
 * 获取用户信息
 * @param params
 * @returns {Promise<unknown>}
 */
export const userinfo = () => get('/auth/userinfo', {})

/**
 * 获取公告
 */
export const getNotice = () => get('/discord/notice-list', {})

/**
 * 获取配置
 */
export const getConfig = (domain, key) => get('/discord/config', {domain, key})

/**
 * 获取配置
 */
export const config = () => get('/auth/config', {})